<template>
  <ValidationProvider :name="options.label" v-slot="{ errors }" :rules="options.rules">
    <v-select
      :error-messages="errors"
      :items="options.items"
      v-model="model"
      item-text="value"
      item-value="key"
      :multiple="options.multiple"
      :label="options.label"
      :placeholder="options.placeholder"
      :chips="options.chips"
      :hint="options.hint"
    />
  </ValidationProvider>
</template>

<script>
export default {
  name: "field",
  props: ["value", "options"],
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>

<style scoped>

</style>
